import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import AdobeLogo from '../components/partner-logos/AdobeLogo';
// import CommercetoolsLogo from '../components/partner-logos/CommercetoolsLogo';
import ShopifyLogo from '../components/partner-logos/ShopifyLogo';
import SalesforceCommerceCloudLogo from '../components/partner-logos/SalesforceCommerceCloudLogo';
import ZoeyLogo from '../components/partner-logos/ZoeyLogo';
import WooCommerceLogo from '../components/partner-logos/WooCommerceLogo';
import KlaviyoLogo from '../components/partner-logos/KlaviyoLogo';
import YotpoLogo from '../components/partner-logos/YotpoLogo';
import ShipperHqLogo from '../components/partner-logos/ShipperHqLogo';
import AvalaraLogo from '../components/partner-logos/AvalaraLogo';
import CybersourceLogo from '../components/partner-logos/CybersourceLogo';
import WorldpayLogo from '../components/partner-logos/WorldpayLogo';
import PayPalLogo from '../components/partner-logos/PayPalLogo';
import BraintreeLogo from '../components/partner-logos/BraintreeLogo';
import AdyenLogo from '../components/partner-logos/AdyenLogo';
import TopBrandsSection from '../components/sections/TopBrandsSection';

const PartnerCard = ({ className, children }) => (
  <div className={` overflow-hidden min-h-[6rem] px-8 py-2 rounded shadow flex justify-center items-center ${className}`}>
    {children}
  </div>
);

const IntegrationsSection = ({ className, containerClassName, gridClassName, children, title, description }) => (
  <section className={`  ${className}`}>
    <Container className={`my-24 ${containerClassName}`}>
      <h2 className="text-center text-5xl mb-2 font-medium text-black">{title}</h2>
      <p className="text-center text-lg mb-10">{description} </p>
      <div className={`grid gap-4 text-black ${gridClassName}`}>{children}</div>
    </Container>
  </section>
);

const IntegrationsPage = () => (
  <Layout>
    <Seo title="Subscribe Pro integrates with your technology" />

    <Header graphic={3}>Integrations</Header>

    <IntegrationsSection
      containerClassName=""
      gridClassName="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 max-w-[1180px] mx-auto"
      title="E-commerce Platform Integrations"
      description="Fully featured integrations for the top e-commerce platforms."
    >
      <PartnerCard>
        <Link to="/adobe-commerce-subscriptions" className="inline-flex items-center" aria-label="Adobe Commerce Subscriptions">
          <AdobeLogo className="h-[3rem] w-auto mr-2" />
          <span>
            <img
              className="h-[1.8rem] w-auto"
              alt="Adobe Commerce"
              title="Adobe Commerce"
              src="/static-images/adobe_commerce.png"
            />
          </span>
        </Link>
      </PartnerCard>
      {/* <PartnerCard>
        <CommercetoolsLogo className="h-[3.2rem] w-auto" />
      </PartnerCard> */}
      <PartnerCard>
        <ZoeyLogo className="h-[3.4rem] w-auto" />
      </PartnerCard>
      <PartnerCard>
        <WooCommerceLogo className="h-[5rem] w-auto" />
      </PartnerCard>
      <PartnerCard>
        <Link
          to="/salesforce-commerce-cloud-subscriptions"
          className="inline-flex items-center"
          aria-label="Salesforce Commerce Cloud Subscriptions"
        >
          <SalesforceCommerceCloudLogo className="h-[3.6rem] w-auto" />
        </Link>
      </PartnerCard>
      <PartnerCard>
        <ShopifyLogo className="h-[3.6rem] w-auto" />
      </PartnerCard>
    </IntegrationsSection>

    <IntegrationsSection
      containerClassName="lg:px-[60px]"
      gridClassName="grid-cols-1 sm:grid-cols-2 lg:grid-cols-4"
      title="Technology Integrations"
      description="Integrate subscription commerce with your full tech stack."
    >
      <PartnerCard>
        <div className="flex flex-col items-center py-8">
          <h3>
            <KlaviyoLogo className="h-[2.2rem] w-auto" />
          </h3>
          <p className="text-center text-sm mt-4">
            Klaviyo provides intelligent marketing automation for faster, more efficient growth.
          </p>
          <a
            className="btn btn-sm btn-sp-green mt-4"
            aria-label="Klaviyo.com (opens in new tab)"
            href="https://www.klaviyo.com/"
            target="_blank"
            rel="noreferrer"
          >
            Klaviyo.com
          </a>
        </div>
      </PartnerCard>

      <PartnerCard>
        <div className="flex flex-col items-center py-8">
          <h3>
            <YotpoLogo className="h-[2rem] w-auto" />
          </h3>
          <p className="text-center text-sm mt-4">
            Yotpo provides connected solutions for reviews, SMS, email, loyalty, and subscriptions.
          </p>
          <a
            className="btn btn-sm btn-sp-green mt-4"
            aria-label="Yotpo.com (opens in new tab)"
            href="https://www.yotpo.com/"
            target="_blank"
            rel="noreferrer"
          >
            Yotpo.com
          </a>
        </div>
      </PartnerCard>

      <PartnerCard>
        <div className="flex flex-col items-center py-8">
          <h3>
            <ShipperHqLogo className="h-[2.4rem] w-auto" />
          </h3>
          <p className="text-center text-sm mt-4">ShipperHQ is the absolute standard for shipping rate calculation.</p>
          <a
            className="btn btn-sm btn-sp-green mt-4"
            aria-label="ShipperHQ.com (opens in new tab)"
            href="https://www.shipperhq.com/"
            target="_blank"
            rel="noreferrer"
          >
            ShipperHQ.com
          </a>
        </div>
      </PartnerCard>

      <PartnerCard>
        <div className="flex flex-col items-center py-8">
          <h3>
            <AvalaraLogo className="h-[1.6rem] w-auto" />
          </h3>
          <p className="text-center text-sm mt-4">
            Avalara software automates tax compliance and can help improve accuracy while reducing costs.
          </p>
          <a
            className="btn btn-sm btn-sp-green mt-4"
            aria-label="Avalara.com (opens in new tab)"
            href="https://www.avalara.com/"
            target="_blank"
            rel="noreferrer"
          >
            Avalara.com
          </a>
        </div>
      </PartnerCard>
    </IntegrationsSection>

    <IntegrationsSection
      containerClassName="lg:px-[120px] mb-[12rem]"
      gridClassName="grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
      title="Featured Payment Integrations"
      description={
        <>
          <p>We offer first class integrations for leading payment providers.</p>
          <p>
            <a href="/payment-providers">See our full list of supported Native and External Payment Providers here.</a>
          </p>
        </>
      }
    >
      <PartnerCard>
        <WorldpayLogo />
      </PartnerCard>
      <PartnerCard>
        <PayPalLogo />
      </PartnerCard>
      <PartnerCard>
        <BraintreeLogo />
      </PartnerCard>
      <PartnerCard>
        <AdyenLogo />
      </PartnerCard>
      <PartnerCard>
        <CybersourceLogo />
      </PartnerCard>
    </IntegrationsSection>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default IntegrationsPage;
